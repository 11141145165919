import { makeRequest } from "./util";

export const API_URL = process.env.REACT_APP_URL;
export const API_Endpoint = process.env.REACT_APP_API_Endpoint;

const FETCH_BOT_API = API_URL + API_Endpoint + "/bot/#id#"
const FETCH_CONTEXT_API = API_URL + API_Endpoint + "/users/context"
const FETCH_RATING_API = API_URL + API_Endpoint + "/chathistory/#chatid#/score"
const FETCH_ALLBOT_API = API_URL + API_Endpoint + "/bot/v3/all"
const FETCH_POPULAR_API = API_URL + API_Endpoint + "/bots/recentlyused"

export const fetchBots = (id) => {
    let url = FETCH_BOT_API.replace("#id#", id);
    let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return makeRequest(url, requestOptions)
} 

export const fetchContext = (tid,enctid) => {
    let url = FETCH_CONTEXT_API
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'ticket-id': tid,
            'enc-ticket-id': enctid
        },
    }

    return makeRequest(url, requestOptions)
} 

export const executeRating = (chatid, sessionID,score) => {
    let url = FETCH_RATING_API.replace("#chatid#", chatid);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "session-id": sessionID },
        body: JSON.stringify({ score: score })
    };

    return makeRequest(url, requestOptions)
} 

export const fetchallbots = (cat_UUID) => {
    let url = FETCH_ALLBOT_API
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ category_uuid: cat_UUID })
    };
    return makeRequest(`${url}?category_uuid=${cat_UUID}`, requestOptions)
} 

export const fetchPopulatbots = (ssoid) => {
    let url = FETCH_POPULAR_API
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',"sso-id": ssoid }
    };
    return makeRequest(`${url}`, requestOptions)
} 