export const getCookie = (cname) => {

    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export const makeRequest = (url, requestOptions) => {
  return fetch(url, requestOptions)
}

export const executeGRX_head = (slot_name, click_page) => {
  let ssoid = localStorage.getItem("ssoID") || '';
  let bot_name = document.getElementById('botName');
  let bot_id = bot_name?.getAttribute('bot-id') || '';
  let bot_cat = bot_name?.getAttribute('bot-cat') || ''

  window.grx('track', 'TOIAssist_Click',
  { body: JSON.stringify({ 
  'click_page': click_page,
  'bot':bot_id,
  'category':bot_cat
 }),
  'slot_name':slot_name,
  'dmpId':'Not Available',
  'userId': ssoid || '',
  'source': 'direct',
  'url':window.location.href
 });
}

export const executeGRX = (slot_name, click_page, bot, category) => {
  let ssoid = localStorage.getItem("ssoID") || '';
  window.grx('track', 'TOIAssist_Click',
  { body: JSON.stringify({ 
  'click_page': click_page,
  'bot':bot,
  'category':category
 }),
  'slot_name':slot_name,
  'dmpId':'Not Available',
  'userId': ssoid || '',
  'source': 'direct',
  'url':window.location.href
 });
}

export const executeGRX_pageview = (slot_name,page_type,bot,primeStatus) => {
  let ssoid = localStorage.getItem("ssoID") || 'Not Available';
  let bot_name = document.getElementById('botName');
  let bot_id = bot_name?.getAttribute('bot-id') || '';
  window.grx('track', 'TOIAssist_PageView',
  { body: JSON.stringify({
    'version': 'website_03Jul2023', 
    'refer_type':'direct',
    'refer_url':'',
    'page_type':page_type || '',
    'bot':bot_id
  }),
  'slot_name':slot_name || '',
  'dmpId':'Not Available',
  'userId': ssoid,
  'primeStatus': primeStatus || 'Not Available',
  'source': 'direct',
  'url':window.location.href || ''
 });
}

export const copytoclip = () =>{
  try{
   if(navigator.clipboard){
      var copyText = document.getElementById("clipboard");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
   }
  } catch(ex){console.log('err',ex)}
}

export const readDisc = (display, type) => {
  var ds = document.getElementById("disclaimer");
  var pv = document.getElementById("pv");
  var tnc = document.getElementById("tnc");
  var copytoclipboard = document.getElementById("copytoclipboard");
  var subscribe = document.getElementById("subscribe");
  if (display) { document.getElementById("bgoverlay").style.display = "block" }
  else { document.getElementById("bgoverlay").style.display = "none" }
  if (type == 'pv') {
      display == true ? pv.className = "show" : pv.className = "hide";
  }
  else if (type == 'tnc') {
      display == true ? tnc.className = "show" : tnc.className = "hide";
  }
  else if (type == 'copytoclipboard') {
      display == true ? copytoclipboard.className = "show" : copytoclipboard.className = "hide";
  }
  else if (type == 'subscribe') {
    display == true ? subscribe.className = "show" : subscribe.className = "hide";
}
  else {
      display == true ? ds.className = "show" : ds.className = "hide";
  }
}

export const sharethruApp = (page,platform) => {
  let bot_name = document.getElementById('botName');
  let bot_id = bot_name?.getAttribute('bot-id') || '';
  let bot_desc = bot_name?.getAttribute('bot-desc') || '';
  let app_share = JSON.stringify({"type": "share", "value":bot_id!=='' ? `${bot_id}\n${bot_desc}\n${window.location.href.split('?')[0]}?utm_medium=referral&utm_name=timesassist&utm_campaign=${platform}app` : `Times Assist\n${window.location.href.split('?')[0]}?utm_medium=referral&utm_name=timesassist&utm_campaign=${platform}app` ,"requestReason": "share"})
  if(platform=='iOS'){
    window.webkit.messageHandlers.tilAppWebBridge.postMessage(app_share)
   
  }
  else{
    window.tilAppWebBridge.postMessage(app_share)
  }
  
 


}