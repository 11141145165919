export const config = { 
    frmapp : {
        "web": {
            "messages" : {
                credit_day_msg:"Credits exhausted.",
                subscribe_toi: "Subscribe to TOI+ or Times Prime",
                unlimited_access:"Unlimited access to Times employees",
                fee_Q_day:"free today. Get TOI+ or Times Prime for more.",
                members_unlimited_access: "TOI+ Members enjoy unlimited access.",
                ta_availability_join: "Times Assist is available for TOI+ users. Join today!",
                subscribe_to_continue:"Subscribe to continue",
                issue_login_session:"Issue with your login session. Please refresh the page or login again.",
                return_tommorrow:"free today. Get TOI+ or Times Prime for more.",
                free_Q_today:"10 free today. Subscribe to TOI+ or Times Prime for more.",
                question_left:"questions left. Return tommorrow or subscribe to TOI+ for more.",
                used_credit:"Credits exhausted.",
                something_went_wrong:"Something went wrong. please try again.",
                members_unlimited_access_TP: "TimesPrime Members enjoy unlimited access.",
            }
        },
        'toi_app' : {
            "messages" : {
                credit_day_msg:"Credits exhausted.",
                subscribe_toi: "Subscribe to TOI+",
                unlimited_access:"Unlimited access to Times employees",
                fee_Q_day:"free today. Get TOI+ for more.",
                members_unlimited_access: "TOI+ Members enjoy unlimited access.",
                ta_availability_join: "Times Assist is available for TOI+ users. Join today!",
                subscribe_to_continue:"Subscribe to continue",
                issue_login_session:"Issue with your login session. Please refresh the page or login again.",
                return_tommorrow:"free today. Get TOI+ for more.",
                free_Q_today:"10 free today. Subscribe to TOI+ for more.",
                question_left:"free today. Get TOI+ for more.",
                used_credit:"Credits exhausted.",
                something_went_wrong:"Something went wrong. please try again.",
                members_unlimited_access_TP: "TimesPrime Members enjoy unlimited access.",
            }

        },
        'timesprime': {
            "messages" : {
                credit_day_msg:"Credits exhausted.",
                subscribe_toi: "Subscribe to Times Prime",
                unlimited_access:"Unlimited access to Times employees",
                fee_Q_day:"free today. Get Times Prime for more.",
                members_unlimited_access: "Times Prime Members enjoy unlimited access.",
                ta_availability_join: "Times Assist is available for Times Prime users. Join today!",
                subscribe_to_continue:"Subscribe to continue",
                issue_login_session:"Issue with your login session. Please refresh the page or login again.",
                return_tommorrow:"free today. Get Times Prime for more.",
                free_Q_today:"10 free today. Subscribe to Times Prime for more.",
                question_left:"free today. Get Times Prime for more.",
                used_credit:"Credits exhausted.",
                something_went_wrong:"Something went wrong. please try again.",
                members_unlimited_access_TP: "TimesPrime Members enjoy unlimited access.",
            }
        }
    },
    "subscriberType": {
        "TOI_PLUS": {
            unlimited_access_msg: "TOI+ Members enjoy unlimited access.",
        },
        "TIMES_PRIME": {
            unlimited_access_msg: "TimesPrime Members enjoy unlimited access.",
        },
        "TIMES_USER":{
            unlimited_access_msg: "Unlimited access to Times employees",
        },
        "NON_PAID_USER":{
            unlimited_access_msg: "Subscribe to TOI+ or Times Prime",
        }
    }

}

export const GRX_PROJECT_ID = "g7af6dd9d"
export const meta_Data = {
    m_title: "Times Assist - Next Generation AI Apps. Meet your team of personal assistants",
    m_desc:"Powered by AI and trained with expansive sets of knowledge, TimesAssist is exclusively available for TOI+ and TimesPrime members.",
    m_img:"https://static.toiimg.com/thumb/102798555.cms?height=630&width=1200"
}
