import './Index.scss';
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import { API_Endpoint, API_URL } from '../../Services/apiService';
import { useCookies } from 'react-cookie';
import { isMobile } from 'mobile-device-detect';
import { fetchPopulatbots } from '../../Services/apiService';
import { executeGRX, executeGRX_pageview, copytoclip, readDisc } from '../../Services/util';
import { config } from '../../Services/config';
// import Meta from '../../components/Meta';
import Bottomsheet from '../../components/Bottomsheet/Bottomsheet';
const Index = (props) => {
    //console.log(process.env.REACT_APP_NODE_ENV, "process.env.REACT_APP_NODE_ENV")
    const { getIsAppLogin } = props;
    const [data, setData] = useState([]);
    const [getcatData, setcatData] = useState([]);
    const [getloadmore, setloadmore] = useState(false);
    const [GetTicketID, SetTicketID] = useState('');
    const [cookies, setCookie] = useCookies([])
    const [getRecentbotData, setRecentbotData] = useState([])
    const [getpopularbotData, setpopularbotData] = useState([])
    const [GetssoDetails, SetssoDetails] = useState('');
    // const [getIsAppLogin, setIsAppLogin] = useState(true);
    let params = new URL(document.location).searchParams;
    let comingfrom = params.get("comingfrom") || '';
    let chCount = isMobile ? 35 : 90;
    useEffect(() => {
        const url = `${API_URL}/${API_Endpoint}/bot/v5/all`
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const fetchInfo = () => {
            try {
                return fetch(url, requestOptions)
                    .then((res) => res.json())
                    .then((d) => {
                        let ishometxt = document.getElementById('homeMain')
                        if (ishometxt) {
                            ishometxt.classList.remove('fadeout')
                            ishometxt.classList.add('fadein')
                        }
                        setpopularbotData(d && d?.most_popular_bots);
                        setData(d && d?.data && d?.data.filter(function (el) { return el.is_active === true }));
                        executeGRX_pageview('website', 'homepage', '')
                    }).catch(ex => { })
            }
            catch (ex) { }
        }

        fetchInfo().then(res => { }).catch(err => { })

        try {
            if (localStorage.getItem("ssoID") !== null) {
                fetchPopulatbots(localStorage.getItem("ssoID"))
                    .then((res) => res.json())
                    .then((d) => {
                        setRecentbotData(d);
                    }).catch(ex => { })
            }
            else {

            }
        } catch (ex) { }
        if (document.getElementById('backbtn')) { document.getElementById('backbtn').style.visibility = "hidden" }

    }, [])


    const history = useHistory();
    const redirectLink = (name, link, page) => {
        window.location.href = link
    }
    const handleClickmore = (vl) => {
        let catArr = getcatData.map(a => { return { ...a } })
        catArr.push({ cat: vl.id })
        setcatData(catArr)
    }

    return (
        <>
            {/* <Meta type="homePage" description="Powered by AI and trained with expansive sets of knowledge, TimesAssist is exclusively available for TOI+ and TimesPrime members." title="Times Assist - Next Generation AI Apps. Meet your team of personal assistants" imageUrl="https://static.toiimg.com/thumb/102798555.cms?height=630&width=1200"/> */}
            <div><div id="bgoverlay" />
                <div>
                    <ul className={(comingfrom in config.frmapp) ? 'cards-app' : 'cards'}>
                        <li className="cards__item" id="catDiv">
                            <div className="card">
                                {/* <div className='knowmoreTA' onClick={showTAjourney}>Know more about Times Assist <i class="fa fa-angle-right"></i></div> */}

                                <div className='card__title'>
                                    {<div>
                                        <div id="homeMain" className="fadeout" style={{ float: 'left', width: '100%' }}>
                                            <div className='bot-parent'>
                                                <div>
                                                    <span className='catTitle'>Meet your team of personal assistants</span><br />
                                                    <span className='card__text'>Powered by AI and trained with expansive sets of knowledge, TimesAssist is exclusively available for TOI+ and TimesPrime members.</span>
                                                </div>
                                            </div>
                                        </div>
                                        {getpopularbotData && getpopularbotData.length > 0 && <div className='maincontainer'>
                                            <div className='bot-main'>
                                                <div className='bot-title'><h3>Popular assistants</h3></div>
                                                <div className='bot-list-main'>
                                                    {getpopularbotData && getpopularbotData?.map((val, index) => {
                                                        const desc = (val && val.description);
                                                        const itemCount = Object.keys(val).length;
                                                        if (index < 4 && itemCount !== 0) return <div key={val.uuid} className={(index === 0 || index === 2) ? 'maincont mr' : 'maincont'}>
                                                            <Link onClick={() => executeGRX('homepage_popular', 'homepage', `${val.name}`, 'Popular assistants')} to={(comingfrom in config.frmapp) ? `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}?comingfrom=${comingfrom}` : `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}`}>
                                                                <div className='bot-list pop'>
                                                                    <div className='iner'>
                                                                        <div>
                                                                            {isMobile ? <img src={val.image_url} height="35" width="35" alt={val.name} /> :
                                                                                <img src={val.image_url} height="50" width="50" alt={val.name} />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {val.name}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div><span className='bot-desc'>{desc && desc.substring(0, chCount)}{isMobile ? '...' : desc.length > chCount ? '...' : ''}</span></div> */}
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    })}
                                                </div>
                                            </div>
                                        </div>}
                                        {getRecentbotData && getRecentbotData.length > 0 && <div className='maincontainer'>
                                            <div className='bot-main'>
                                                <div className='bot-title'><h3>Recently used assistants</h3></div>
                                                <div className='bot-list-main'>
                                                    {getRecentbotData && getRecentbotData.map((val, index) => {
                                                        const desc = (val && val.description);
                                                        if (index < 4) return <div key={val.uuid} className={(index === 0 || index === 2) ? 'maincont mr' : 'maincont'}>
                                                            <Link onClick={() => executeGRX('homepage_recent', 'homepage', `${val.name}`, 'Recently used assistants')} to={(comingfrom in config.frmapp) ? `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}?comingfrom=${comingfrom}` : `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}`}>
                                                                <div className='bot-list pop'>
                                                                    <div className='iner'>
                                                                        <div>
                                                                        {isMobile ? <img src={val.image_url} height="35" width="35" alt={val.name} /> :
                                                                                <img src={val.image_url} height="50" width="50" alt={val.name} />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {val.name}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div><span className='bot-desc'>{desc && desc.substring(0, chCount)}{isMobile ? '...' : desc.length > chCount ? '...' : ''}</span></div> */}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>}

                                        <div className='maincontainer'>{data && data?.map((item, index) => (
                                            <div className='bot-main'>
                                                <div className='bot-title'><h3>{item.name}</h3></div>
                                                <div className='cat-desc'>{item.description}</div>
                                                <div className='bot-list-main'>
                                                    {item && item?.bots && item?.bots.map((val, index) => {
                                                        const desc = (val && val.description);
                                                        if (index < 20) return <div key={val.uuid} className={(index % 2 === 0) ? 'maincont mr' : 'maincont'}>
                                                            <Link onClick={() => executeGRX('homepage_bot', 'homepage', `${val.name}`, item.name)} to={(comingfrom in config.frmapp) ? `${process.env.REACT_APP_HOMEPAGE}/cat/${item.uuid}/conv/${val.uuid}?comingfrom=${comingfrom}` : `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}`}>
                                                                <div className='bot-list'>
                                                                    <div className='iner'>
                                                                        <div>
                                                                        {isMobile ? <img src={val.image_url} height="35" width="35" alt={val.name} /> :
                                                                                <img src={val.image_url} height="50" width="50" alt={val.name} />
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {val.name}
                                                                        </div>
                                                                    </div>
                                                                    <div><span className='bot-desc'>{desc && desc.substring(0, chCount)}{isMobile ? '...' : desc.length > chCount ? '...' : ''}</span></div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        else if (index > 2 && (getcatData && getcatData.filter(function (el) { return el.cat == item.name })).length > 0) return <div>
                                                            <Link onClick={() => executeGRX('homepage_bot', 'homepage', `${val.name}`, item.name)} to={(comingfrom in config.frmapp) ? `${process.env.REACT_APP_HOMEPAGE}/cat/${item.uuid}/conv/${val.uuid}?comingfrom=${comingfrom}` : `${process.env.REACT_APP_HOMEPAGE}/cat/8e3a8763cf/conv/${val.uuid}`}>
                                                                <div className='bot-list'>
                                                                    <div className='bot-img'>
                                                                        <img src={val.image_url} height="50" width="50" alt={val.name} />
                                                                    </div>
                                                                    <div className='bot-content'>
                                                                        {val.name}<br />
                                                                        <span className='bot-desc'>{val.description}</span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        else if (index == 3 && !(getcatData && getcatData.filter(function (el) { return el.cat == item.name })).length > 0) return <div id={item.name} className='seeall' onClick={(e) => { handleClickmore(e.currentTarget); executeGRX('homepage_see_all', 'homepage', '', item.name) }}><div className='seeallbot'>See all</div></div>

                                                    })}
                                                </div>

                                            </div>
                                        )
                                        )}</div>
                                    </div>
                                    }
                                </div>
                                {/* <div className='applyflex'>
                                    <div className='card__title inactive' style={{ position: 'relative' }}>
                                        <div className='content'>
                                            <div>
                                                <span className='bg-speakingtree'></span>
                                            </div>
                                            <div>Speaking Tree<br /><span class="card__text inactive">Contemplate on life’s bigger questions with AI</span></div>
                                        </div>
                                        <div class="cmng-soon">
                                            <span className='cmng-msg'>Coming soon <i class="fa fa-lock"></i></span>
                                        </div>
                                    </div>
                                </div> */}
                                {!(comingfrom in config.frmapp) && <div className='mailto'>
                                    <a href="javascript:void(0)" onClick={() => { redirectLink('mail_to', 'mailto:times.assist@timesinternet.in'); executeGRX('homepage_feedback', 'homepage', '', '') }}>
                                        <div className='catimg'><span className="bg-email"></span></div>
                                        <div className='cattitle spk'>Suggest an assistant</div>
                                    </a>
                                </div>}
                            </div>
                        </li>
                    </ul>
                    <div style={{ marginLeft: '4%' }}>
                        <Bottomsheet page="homepage" type="copytoclipboard" readDisc={readDisc} copytoclip={copytoclip} botname="Meet your team of personal assistants" botdesc="Powered by AI and trained with expansive sets of knowledge, TimesAssist is exclusively available for TOI+ and TimesPrime members." botimg="https://static.toiimg.com/thumb/102798555.cms?height=630&width=1200"/>
                    </div>
                </div>
                <Footer />

            </div>

        </>
    )
}

export default Index;



