import React from 'react';
import './Footer.scss';
import { useState, useEffect, useSearchParams } from "react";
import { executeGRX } from '../../Services/util';
import {executeGRX_head } from '../../Services/util';
import { config } from '../../Services/config';

const Footer = React.memo(props => {
    let params = new URL(document.location).searchParams;
    let comingfrom = params.get("comingfrom") || '';
    return(
        <div className='footer'>
            <div className='tandc'>
                <a onClick={()=>executeGRX_head('tnc','homepage')} href={(comingfrom in config.frmapp) ? `https://www.indiatimes.com/termsandcondition?comingfrom=${comingfrom}` : `https://www.indiatimes.com/termsandcondition`} target="_blank">Terms & Conditions</a>
                <a onClick={()=>executeGRX_head('privacy','homepage')} href={(comingfrom in config.frmapp) ? `https://timesofindia.indiatimes.com/privacy-policy/cookiepolicy/86934312.cms?comingfrom=${comingfrom}` : `https://timesofindia.indiatimes.com/privacy-policy/cookiepolicy/86934312.cms`} target="_blank">Privacy Policy</a>
            </div>
            <div className='copyright'>
            Copyright © 2023  Bennett, Coleman & Co. Ltd. All rights reserved.For reprint rights: Times Syndication Service
    The Times of India
            </div>
            
        </div>
    )
})

export default Footer;