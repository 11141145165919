import React, { useState } from 'react';
import './Header.scss';
import { useRef, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import { executeGRX_head } from '../../Services/util';
import Getsvg from '../Getsvg'

(function (w, s, el) {
  var sc = document.createElement("script");
  w[el] = w[el] || function () {
    w[el] = w[el] || {};
    w[el].ev = w[el].ev || [];
    w[el].ev.push(arguments);
  }
  sc.type = "text/javascript";
  sc.defer = 1;
  sc.src = s;
  document.getElementsByTagName("head")[0].appendChild(sc);
})(window, `${process.env.REACT_APP_JSSO_CDN_URL}`, "ssoWidget");


const Header = (props) => {
  const ref = useRef(null);
  const REACT_APP_ROUTE_TO_HOME = process.env.REACT_APP_ROUTE_TO_HOME;
  const { loginconfig, UserDetails, setUserDetails, ssoOpen, SetSsoOpen } = props;
  const [cookies, setCookie] = useCookies([]);
  useEffect(() => {
    try {
      window.jsso.getValidLoggedInUser((res) => {
        if (res.status == "SUCCESS") {
          localStorage.setItem('ticketId', res && res?.data && res?.data?.ticketId);
          localStorage.setItem('encTicket', res && res?.data && res?.data?.encTicket);
          window.jsso.getUserDetails((res) => {
            if (res.status == "SUCCESS") {
              localStorage.setItem('ssoID', res && res?.data && res?.data?.ssoid);
              setUserDetails(res);
              document.getElementById("loginDv").style.display = "none";
              document.getElementById("logoutDv").style.display = "block";
              //localStorage.setItem('ssoID', res && res?.data && res?.data?.ssoid);
            }
          });
        }
        else{
          localStorage.clear("ssoID");
          localStorage.clear("encTicket");
          localStorage.clear("ticketId");
          let log2 = document.getElementById("loginDv2")
          if(log2){log2.style.display = "block";}
          document.getElementById("logoutDv").style.display = "none";
          
        }
      })
    } catch (ex) { }
  }, [])

  useEffect(() => {
    document.getElementById("helloUser").style.display = "block";
    document.getElementById("prof_img").src = UserDetails && UserDetails?.data && UserDetails?.data?.thumbImageUrl || ''
    window.addEventListener('mouseup', function (event) {
      let logProfile = document.getElementById('list-items');
      if (event.target != logProfile && event.target.parentNode != logProfile) {
        logProfile.style.display = 'none';
      }
    });
  }, [UserDetails])

  useEffect(() => {
    const handleClick = event => {
      //console.log('Button clicked');
      if (window.location.pathname.split("/")[5] !== undefined) {
        let bot_name = document.getElementById('botName')
        if (bot_name) { executeGRX_head('ta_logo', 'chatpage') }
      }
      else {
        executeGRX_head('ta_logo', 'homepage')
      }

    };
    const element = ref.current;
    //console.log(element)
    element.addEventListener('click', handleClick);
    return () => {
      element.removeEventListener('click', handleClick);
    };


  }, [window.location.pathname, UserDetails])

  const logout = () => {
    window.jsso.signOutUser(() => {
      document.getElementById("loginDv").style.display = "block"
      document.getElementById("logoutDv").style.display = "none";
      document.getElementById("helloUser").style.display = "none";
      setUserDetails([]);
      if (window.location.pathname.split("/")[5] !== undefined) {
        executeGRX_head('logout', 'chatpage')
      }
      else {
        executeGRX_head('logout', 'homepage')
      }

      localStorage.clear("ssoID");
      localStorage.clear("encTicket");
      localStorage.clear("ticketId");
      window.location.reload();
    })
  }
  const handlessoClick = () => {
    document.getElementById("ssoLogin").style.display = "block";
    SetSsoOpen(true);
    window.ssoWidget("init", loginconfig);
    if (window.location.pathname.split("/")[5] !== undefined) { executeGRX_head('login', 'chatpage') }
    else { executeGRX_head('login', 'homepage') }

  }
  const show_hide = () => {
    var click = document.getElementById("list-items");
    if (click.style.display === "none") {
      click.style.display = "block";
    } else {
      click.style.display = "none";
    }
  }
  const executeSkip = () => {
    setCookie("onboarded", true, { path: "/", "maxAge": "864000" });
    window.location.href = REACT_APP_ROUTE_TO_HOME
  }

  const handleProfile = () => {
    if (window.location.pathname.split("/")[5] !== undefined) { executeGRX_head('profile_pic', 'chatpage') }
    else { executeGRX_head('profile_pic', 'homepage') }
  }
  const handleCopyclip = (display, type) => {
    var copytoclipboard = document.getElementById("copytoclipboard");
    if (display) {document.getElementById("bgoverlay").style.display = "block" }
    else { document.getElementById("bgoverlay").style.display = "none" }
    if (type == 'copytoclipboard') {
      display == true ? copytoclipboard.className = "show" : copytoclipboard.className = "hide";
    
    }
     
   
}
  return (
    <div style={{height:'50px'}}>
      <div className={`${ssoOpen ? "ssoLogin ssoBlock" : "ssoLogin"}`} id="ssoLogin" />
      <div id="bgoverlay" />
      <nav className='stickynav'>
        <div className='main-nav'>
          <div className='back' onClick={() => executeGRX_head('back_btn', 'chatpage')}><Link to={REACT_APP_ROUTE_TO_HOME} id="backbtn" style={{ visibility: 'hidden' }}><i className="arrow left" /></Link></div>
          <div className='logo' ref={ref}>
            <Link to={REACT_APP_ROUTE_TO_HOME}><img src="https://static.toiimg.com/photo/102912191.cms" height="16" width="150"/><br /><span>Experiment</span></Link>
          </div>
          <div id="skipintro"><a onClick={() => executeSkip()}>Skip <i className="fa fa-angle-right"></i></a></div>
          <div id="shareicon" style={{float:'left',visibility:'visible'}} onClick={() =>{executeGRX_head('ta_share_btn', 'chatpage'); handleCopyclip(true, 'copytoclipboard')}}>
            <Getsvg width="21" height="23"/>
          </div>
          <div className='profile' id="profile" onClick={() => handleProfile()}>
            <div id="loginDv"><a onClick={handlessoClick}>Login</a></div>
            <div id="logoutDv">
              <img id="prof_img" src="https://static.toiimg.com/photo/100803177.cms" height={'31'} width={'31'} onClick={show_hide} />
              <div style={{ display: 'none' }} id="list-items">
                <ul>
                  <li id="helloUser">{UserDetails && UserDetails?.data && UserDetails?.data?.firstName ? 'Hi, ' + UserDetails?.data?.firstName : ''}</li>
                  <li><a onClick={logout}>Logout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      
    </div>
  )
}

export default Header;