import React, { useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Bottomsheet.scss';


import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
export default function Bottomsheet(props) {
    const { page, type, readDisc, botname, botdesc, botimg } = props
    const [getShareDetails, setShareDetails] = useState({});
    const [getUsrName, setUsrName] = useState({});
    const [getisCopy, setisCopy] = useState('');
    let botDtls = getShareDetails && getShareDetails?.share_chat_id && getShareDetails?.share_chat_id?.bot
    let shareableObj = { 'url': window.location.href, 'botname': botname, 'botdesc': botdesc, 'hashtag': '#timesassist', }
    const utm_param = "?utm_campaign_source=website&utm_campaign_medium=share&utm_campaign_name=timesassist"
    if (type === 'copytoclipboard') {
        return (
            <div id="copytoclipboard">
                <div className='disctext-share'>
                    <div>
                    <div className="share-hd">
                        <div><img src="https://static.toiimg.com/photo/102912191.cms" width="161" height="20" /></div>
                        <div>
                            <i className="fa fa-close" onClick={() => readDisc(false, 'copytoclipboard')}></i>
                        </div>
                    </div>
                    <div>
                        <div className="botdtls">
                            <div className='disclose share'>
                                <span>Share: {botname}</span>
                                <p className="sharedesc">{botdesc}</p>
                            </div>
                        </div>

                    </div>
                    </div>
                    <div>
                        <div className="share-icon">
                            <WhatsappShareButton
                                url={`${shareableObj.url}${utm_param}`}
                                title={shareableObj.botname}
                                separator=":"
                            >
                                <WhatsappIcon size={45} round />
                            </WhatsappShareButton>
                            <FacebookShareButton
                                url={`${shareableObj.url}${utm_param}`}
                                quote={shareableObj.botname}
                                hashtag={shareableObj.hashtag}
                            >
                                <FacebookIcon size={45} round />
                            </FacebookShareButton>
                            <EmailShareButton
                                url={`${shareableObj.url}${utm_param}`}
                                subject={shareableObj.botname}
                                body={shareableObj.botdesc}
                                separator=":"
                            >
                                <EmailIcon size={45} round />
                            </EmailShareButton>
                            <TelegramShareButton
                                url={`${shareableObj.url}${utm_param}`}
                                title={shareableObj.botname}
                            >
                                <TelegramIcon size={45} round />
                            </TelegramShareButton>
                            <TwitterShareButton
                                url={`${shareableObj.url}${utm_param}`}
                                title={shareableObj.botname}
                                via="timesassist"
                                hashtag={shareableObj.hashtag}
                            >
                                <TwitterIcon size={45} round />
                            </TwitterShareButton>
                            <div className="posrel">
                                <CopyToClipboard text={`${window.location.href}${utm_param}`}
                                    onCopy={() => setisCopy(true)}>
                                    <div className='copyclipClass'>
                                        <div className="copy-in"><i class="fa fa-clipboard"></i></div>
                                    </div>
                                </CopyToClipboard>
                                {getisCopy && <span className="isCopied">Copied</span>}
                            </div>
                        </div>
                    </div>
                    {/* <div className='copyclipClass circle'><div onClick={() => copytoclip()}><i class="fa fa-clipboard"></i></div></div> */}
                </div>

            </div>
        )
    }


}